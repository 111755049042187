export const theme = {
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    lightTextDefault: '#0C0C0DE0',
    lightTextSubtle: '#0C0C0DB8',
    lightTextNonessential: '#0C0C0D7A',
    lightTextOnFill: '#FEFEFF',
    lightTextError: '#A91F1D',
    lightTextSuccess: '#1BC47D',
    lightPrimaryPrimaryMain: '#325F64',
    lightTextPrimary: '#325F64',
    lightStrokeSubtle: '#0C0C0D14',
  },
  breakpoints: {
    mobile: `(max-width: 768px)`,
  },
};

export type Theme = typeof theme;
export type ThemeColors = keyof typeof theme.colors;

export type ThemeTextVariants =
  | 'bodyLarge'
  | 'bodySmall'
  | 'bodyMedium'
  | 'headlineLarge'
  | 'labelLarge'
  | 'labelMedium'
  | 'titleMedium';
