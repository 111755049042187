import styled, { css } from 'styled-components';

import { ThemeColors, ThemeTextVariants } from '../../styles/theme';

export const headlineLargeStyle = css`
  font-size: 32px;
  line-height: 40px;
`;

export const bodySmallStyle = css`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

export const bodyMediumStyle = css`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const bodyLargeStyle = css`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
`;

export const labelLargeStyle = css`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

export const titleMediumStyle = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
`;

export const labelMediumStyle = css`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
`;

interface StyledTextProps {
  $variant?: ThemeTextVariants;
  $color?: ThemeColors;
}

export const StyledText = styled.p<StyledTextProps>`
  font-style: normal;
  font-weight: 400;
  color: ${({ theme, $color }) => theme.colors[$color ?? 'black']};

  ${(props) => props.$variant === 'headlineLarge' && headlineLargeStyle}

  ${(props) => props.$variant === 'bodyLarge' && bodyLargeStyle}
  
  ${(props) => props.$variant === 'bodyMedium' && bodyMediumStyle}

  ${(props) => props.$variant === 'bodySmall' && bodySmallStyle}

  ${(props) => props.$variant === 'labelLarge' && labelLargeStyle}

  ${(props) => props.$variant === 'labelMedium' && labelMediumStyle}
    
  ${(props) => props.$variant === 'titleMedium' && titleMediumStyle}
`;
