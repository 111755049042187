import { StyledLoader, StyledLoaderContainer } from './styles';
import Text from '../Text';

const Loader = () => (
  <StyledLoaderContainer>
    <StyledLoader />{' '}
    <Text $variant="bodySmall" $color="lightTextDefault">
      Loading...
    </Text>
  </StyledLoaderContainer>
);

export default Loader;
