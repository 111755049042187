import Logo from '../Logo';
import { Navbar } from '../Navbar';
import { Container } from './styles';

const Header = () => {
  return (
    <Container>
      <Logo />
      <Navbar />
    </Container>
  );
};

export default Header;
