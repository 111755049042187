import { ApolloProvider } from '@apollo/client';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Header from './components/Header';
import { GlobalStyle } from './styles/global';
import { theme } from './styles/theme';
import client from './clients';
import { lazy, Suspense } from 'react';
import Loader from './components/Loader';

const ParticipantsScreen = lazy(() => import('./pages/Participants'));
const ParticipantsEnrollScreen = lazy(
  () => import('./pages/ParticipantsEnroll')
);
const EligibilityScreen = lazy(() => import('./pages/Eligibility'));
const TrialsScreen = lazy(() => import('./pages/Trials'));

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Header />
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/participants" element={<ParticipantsScreen />} />
              <Route
                path="/participants/enroll"
                element={<ParticipantsEnrollScreen />}
              />
              <Route
                path="/participants/eligibility"
                element={<EligibilityScreen />}
              />
              <Route path="/trials" element={<TrialsScreen />} />
              <Route
                path="*"
                element={<Navigate to="/participants" replace />}
              />
            </Routes>
          </Suspense>
        </ThemeProvider>
      </Router>
    </ApolloProvider>
  );
}

export default App;
