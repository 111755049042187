import { ApolloClient, InMemoryCache } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';

const apolloInstance = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          participants: offsetLimitPagination(),
        },
      },
    },
  }),
  headers: {
    'content-type': 'application/json',
    'x-hasura-admin-secret': process.env.REACT_APP_API_SECRET as string,
  },
});

export default apolloInstance;
