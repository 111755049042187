import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { titleMediumStyle } from '../Text/styles';

export const StyledNavbar = styled.nav`
  display: flex;
  align-items: center;

  a {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin-top: 12px;
  }
`;

export const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.lightTextNonessential};
  ${titleMediumStyle};

  &.active {
    color: ${(props) => props.theme.colors.lightTextPrimary};
  }
`;
