import { StyledLink, StyledNavbar } from './styles';

export const Navbar = () => {
  return (
    <StyledNavbar role="navigation">
      <StyledLink to="/participants">Participants</StyledLink>
      <StyledLink to="/trials">Trials</StyledLink>
    </StyledNavbar>
  );
};
