import styled from 'styled-components';

export const Container = styled.header`
  background: #f5f5f5;
  width: 100%;
  height: 148px;
  display: flex;
  justify-content: space-between;
  padding: 34px 60px;
  margin-bottom: 50px;

  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex-direction: column;
    height: auto;
    align-items: center;
  }
`;
