import styled, { keyframes } from 'styled-components';

export const StyledLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const StyledLoader = styled.div`
  border: 3px solid #eee;
  border-right: 3px solid
    ${({ theme }) => theme.colors['lightPrimaryPrimaryMain']};
  border-radius: 100%;
  width: 25px;
  height: 25px;
  margin-right: 8px;
  animation: ${rotate} 0.8s linear infinite;
`;
